import React from "react"
import { Link } from "gatsby"
import * as Yup from "yup"
import Layout from "../components/layout"
import Head from "../components/head"
import axios from "axios"
import { Formik } from "formik"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { faExclamationTriangle } from "@fortawesome/free-solid-svg-icons"
const URL = "https://assets.itxs.jp/wp-json"
const USER = "itxs-owner"
const PASSWORD = "jdss TTgn iW0E z4Kh OcJ6 Y4r5"
const TOKEN =
  typeof window !== "undefined" && window.btoa(`${USER}:${PASSWORD}`) // Convert Base64
const CF7_ID = "2674"
const phoneRegExp =
  /^((\\+[1-9]{1,4}[ \\-]*)|(\\([0-9]{2,3}\\)[ \\-]*)|([0-9]{2,4})[ \\-]*)*?[0-9]{3,4}?[ \\-]*[0-9]{3,4}?$/
const formSchema = Yup.object().shape({
  yourCompany: Yup.string().required("必須項目です。入力をお願いします。"),
  yourName: Yup.string().required("必須項目です。入力をお願いします。"),
  yourTel: Yup.string()
    .matches(phoneRegExp, "電話番号の形式に誤りがあります")
    .required("必須項目です。入力をお願いします。"),
  yourEmail: Yup.string()
    .email("メールアドレスの形式で入力してください。")
    .required("必須項目です。入力をお願いします。"),
  yourMessage: Yup.string().required("必須項目です。入力をお願いします。"),
  acceptanceConfirm: Yup.boolean().oneOf(
    [true],
    "必須項目です。入力をお願いします。"
  ),
})
// function convertJsontoUrlencoded(obj) {
//   let str = []
//   for (let key in obj) {
//     if (obj.hasOwnProperty(key)) {
//       str.push(encodeURIComponent(key) + "=" + encodeURIComponent(obj[key]))
//     }
//   }
//   return str.join("&")
// }
const ContactPage = () => {
  const [state, setState] = React.useState(null || "")
  return (
    <Layout>
      <Head
        title="お問い合わせ"
        description="株式会社アイテクスのお問い合わせ窓口になります。どうぞお気軽にお問い合わせください。"
      />
      <section className="hero is-halfheight">
        <div className="hero-body has-text-centered">
          <div className="container">
            <h1 className="title">お問い合わせ</h1>
            <h2 className="subtitle">Contact</h2>
          </div>
        </div>
      </section>
      <section className="section is-medium has-background-white-bis">
        <div className="container is-max-desktop">
          <div className="content">
            <p className="has-text-centered">
              株式会社アイテクスへのお問い合わせ、ご意見につきましては、
              <br />
              以下のフォームよりお願いいたします。
            </p>
          </div>
          <div className="has-max-width-600">
            <Formik
              initialValues={{
                yourCompany: "",
                yourDepartment: "",
                yourTel: "",
                yourName: "",
                yourEmail: "",
                yourSubject: "",
                yourMessage: "",
                acceptanceConfirm: false,
              }}
              validationSchema={formSchema}
              onSubmit={(values, { setSubmitting, resetForm }) => {
                const data = new FormData()
                data.append("yourCompany", values.yourCompany)
                data.append("yourDepartment", values.yourDepartment)
                data.append("yourTel", values.yourTel)
                data.append("yourName", values.yourName)
                data.append("yourEmail", values.yourEmail)
                data.append("yourSubject", values.yourSubject)
                data.append("yourMessage", values.yourMessage)
                data.append("acceptanceConfirm", values.acceptanceConfirm)
                const submitData = async () => {
                  try {
                    const result = await axios({
                      url: `${URL}/contact-form-7/v1/contact-forms/${CF7_ID}/feedback`,
                      headers: {
                        Authorization: `Basic ${TOKEN}`,
                        "Content-Type": "multipart/form-data; charset=utf-8",
                      },
                      method: "POST",
                      //data: convertJsontoUrlencoded(values),
                      data: data,
                    })
                    setState(result.data.message)
                    setSubmitting(false)
                    resetForm({})
                    document.getElementById("acceptanceConfirm").checked = false
                  } catch (error) {
                    setState("送信に失敗しました。再度お試しください。")
                  }
                }
                submitData()
              }}
            >
              {({
                values,
                errors,
                touched,
                handleChange,
                handleBlur,
                handleSubmit,
                isSubmitting,
              }) => (
                <form onSubmit={handleSubmit}>
                  <div className="field">
                    <label className="label" htmlFor="yourCompany">
                      御社名<span className="tag is-danger ml-1">必須</span>
                    </label>
                    <div className="control has-icons-right">
                      <input
                        className={`input ${
                          errors.yourCompany &&
                          touched.yourCompany &&
                          "is-danger"
                        }`}
                        type="text"
                        name="yourCompany"
                        onChange={handleChange}
                        onBlur={handleBlur}
                        value={values.yourCompany}
                        placeholder="株式会社アイテクス"
                        size="40"
                      />
                      {errors.yourCompany && touched.yourCompany && (
                        <>
                          <span className="icon is-small is-right">
                            <FontAwesomeIcon icon={faExclamationTriangle} />
                          </span>
                          <p className="help is-danger">{errors.yourCompany}</p>
                        </>
                      )}
                    </div>
                  </div>
                  <div className="field">
                    <label className="label" htmlFor="yourDepartment">
                      部署名
                    </label>
                    <div className="control">
                      <input
                        className={`input ${
                          errors.yourDepartment &&
                          touched.yourDepartment &&
                          "is-danger"
                        }`}
                        type="text"
                        name="yourDepartment"
                        onChange={handleChange}
                        onBlur={handleBlur}
                        value={values.yourDepartment}
                        placeholder="営業部"
                        size="40"
                      />
                      {errors.yourDepartment && touched.yourDepartment ? (
                        <p className="help is-danger">
                          {errors.yourDepartment}
                        </p>
                      ) : null}
                    </div>
                  </div>
                  <div className="field">
                    <label className="label" htmlFor="yourName">
                      お名前<span className="tag is-danger ml-1">必須</span>
                    </label>
                    <div className="control has-icons-right">
                      <input
                        className={`input ${
                          errors.yourName && touched.yourName && "is-danger"
                        }`}
                        type="text"
                        name="yourName"
                        onChange={handleChange}
                        onBlur={handleBlur}
                        value={values.yourName}
                        placeholder="山田 太郎"
                        size="40"
                      />
                      {errors.yourName && touched.yourName && (
                        <>
                          <span className="icon is-small is-right">
                            <FontAwesomeIcon icon={faExclamationTriangle} />
                          </span>
                          <p className="help is-danger">{errors.yourName}</p>
                        </>
                      )}
                    </div>
                  </div>
                  <div className="field">
                    <label className="label" htmlFor="yourTel">
                      電話番号<span className="tag is-danger ml-1">必須</span>
                    </label>
                    <div className="control has-icons-right">
                      <input
                        className={`input ${
                          errors.yourTel && touched.yourTel && "is-danger"
                        }`}
                        type="text"
                        name="yourTel"
                        onChange={handleChange}
                        onBlur={handleBlur}
                        value={values.yourTel}
                        placeholder="050-5213-9261"
                        size="40"
                      />
                      {errors.yourTel && touched.yourTel && (
                        <>
                          <span className="icon is-small is-right">
                            <FontAwesomeIcon icon={faExclamationTriangle} />
                          </span>
                          <p className="help is-danger">{errors.yourTel}</p>
                        </>
                      )}
                    </div>
                  </div>
                  <div className="field">
                    <label className="label" htmlFor="yourEmail">
                      メールアドレス
                      <span className="tag is-danger ml-1">必須</span>
                    </label>
                    <div className="control has-icons-right">
                      <input
                        className={`input ${
                          errors.yourEmail && touched.yourEmail && "is-danger"
                        }`}
                        type="text"
                        name="yourEmail"
                        onChange={handleChange}
                        onBlur={handleBlur}
                        value={values.yourEmail}
                        placeholder="example@itxs.jp"
                        size="40"
                      />
                      {errors.yourEmail && touched.yourEmail && (
                        <>
                          <span className="icon is-small is-right">
                            <FontAwesomeIcon icon={faExclamationTriangle} />
                          </span>
                          <p className="help is-danger">{errors.yourEmail}</p>
                        </>
                      )}
                    </div>
                  </div>
                  <div className="field">
                    <label className="label" htmlFor="yourSubject">
                      題名
                    </label>
                    <div className="control">
                      <input
                        className={`input ${
                          errors.yourSubject &&
                          touched.yourSubject &&
                          "is-danger"
                        }`}
                        type="text"
                        name="yourSubject"
                        onChange={handleChange}
                        onBlur={handleBlur}
                        value={values.yourSubject}
                        placeholder=""
                        size="40"
                      />
                      {errors.yourSubject && touched.yourSubject && (
                        <>
                          <span className="icon is-small is-right">
                            <FontAwesomeIcon icon={faExclamationTriangle} />
                          </span>
                          <p className="help is-danger">{errors.yourSubject}</p>
                        </>
                      )}
                    </div>
                  </div>
                  <div className="field">
                    <label className="label" htmlFor="yourMessage">
                      お問い合わせ内容
                      <span className="tag is-danger ml-1">必須</span>
                    </label>
                    <div className="control has-icons-right">
                      <textarea
                        className={`textarea ${
                          errors.yourMessage &&
                          touched.yourMessage &&
                          "is-danger"
                        }`}
                        name="yourMessage"
                        onChange={handleChange}
                        onBlur={handleBlur}
                        value={values.yourMessage}
                        placeholder="お問い合わせ内容を入力してください"
                        cols="40"
                        rows="10"
                      ></textarea>
                      {errors.yourMessage && touched.yourMessage && (
                        <>
                          <span className="icon is-small is-right">
                            <FontAwesomeIcon icon={faExclamationTriangle} />
                          </span>
                          <p className="help is-danger">{errors.yourMessage}</p>
                        </>
                      )}
                    </div>
                  </div>
                  <div className="field">
                    <div className="control">
                      <label className="checkbox">
                        <input
                          id="acceptanceConfirm"
                          type="checkbox"
                          name="acceptanceConfirm"
                          onChange={handleChange}
                          onBlur={handleBlur}
                          value={values.acceptanceConfirm}
                        />
                        「<Link to="/privacy-policy">プライバシーポリシー</Link>
                        」および「
                        <Link to="/security">情報セキュリティ基本方針</Link>
                        」を必ずご覧いただき、同意の上お問い合わせください。
                      </label>
                      {errors.acceptanceConfirm && touched.acceptanceConfirm && (
                        <>
                          <p className="help is-danger">
                            <span className="icon is-small">
                              <FontAwesomeIcon icon={faExclamationTriangle} />
                            </span>
                            {errors.acceptanceConfirm}
                          </p>
                        </>
                      )}
                    </div>
                  </div>
                  <div className="field is-grouped">
                    <div className="control">
                      <button
                        className="button is-primary"
                        type="submit"
                        disabled={isSubmitting}
                      >
                        送信
                      </button>
                    </div>
                  </div>
                </form>
              )}
            </Formik>
            {state ? (
              <div className="notification is-info is-light mt-5">{state}</div>
            ) : null}
          </div>
        </div>
      </section>
    </Layout>
  )
}

export default ContactPage
